.VideoBlockPlayBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.VideoBlockPlayBackground svg {
  color: white;
  width: 42px;
  height: 42px;
  transition: 200ms;
}

.VideoBlockPlayBackground:hover > svg {
  width: 46px;
  height: 46px;
}
