.Layout__block__sidebar {
    width: 72px;
}

.sidebar__root__mobile {
    display: none;
    height: 64px;
}

.sidebar__root {
    height: 100vh;
    z-index: 1;
}

.sidebar__box {
    top: 24px;
    left: 24px;
    height: calc(100vh - 48px)!important;
    position: fixed;
    width: 72px;
    overflow: auto;
}

@media screen and (min-width: 1366px){
    .Layout__block__sidebar {
        width: 226px;
    }

    .sidebar__box {
        width: 226px;
    }
}

@media screen and (max-width: 1023px){
    .sidebar__root {
        display: none !important;
    }
}

@media screen and (max-width: 1023px){
    .sidebar__root__mobile {
        display: block;
    }
}