.NavItem__block {
    color: white;
    display: flex;
    align-items: center;
    width: max-content;
    cursor: pointer;
    height: 48px;
}

.NavItem__block button {
    color: white;
}

.NavItem__block a {
    color: inherit;
    display: flex;
    align-items: center;
    width: max-content;
    cursor: pointer;
    height: 48px;
    text-decoration: none;
}

.NavItem__block[data-active] {
    color: rgba(29, 138, 254, 1);
}

.NavItem__block[data-active] button {
    color: rgba(29, 138, 254, 1);
}

.NavItem__block:hover button {
    background-color: rgba(29, 138, 254, 0.16);
}