.tox-tinymce-aux {
  z-index: 10000 !important;
}

.filepond--panel-root {
  border-radius: 0;
}

.tox-tinymce--toolbar-sticky-off,
.tox-tinymce--toolbar-sticky-on {
  min-height: 58px !important;
  height: 100% !important;
}
