html,
body,
#root {
  min-height: 100vh;
  min-width: 100vw;
}

body {
  overflow-x: hidden;
}
